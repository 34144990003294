import { ModelsAdminUser, ModelsOrganizationResponse } from './generated/model';
import { faker } from '@faker-js/faker';

export const createOrganization = (
  overrides: Partial<ModelsOrganizationResponse> = {}
): ModelsOrganizationResponse => ({
  externalId: 'da9497e3-ef06-45e9-9e9e-71f6b4a317f6',
  id: 'adbb132c-cf80-4941-ad78-7546ef95acc6',
  name: 'Powerhouse Inclusive B.V.',
  ean13: '1938287462810',
  marketRole: 'LV',
  adminUsers: [
    {
      id: '7a9d9a1e-df11-4b1e-8817-135d76501e0d',
      email: 'diemer.waterschoot@edsn.nl',
    },
  ],
  parentId: 'dc9397d9-000d-480d-8709-05179db21d94',
  organizationStructure: {
    externalId: '',
    id: 'dc9397d9-000d-480d-8709-05179db21d94',
    marketParticipants: [
      {
        ean13: '1938287462810',
        externalId: '',
        id: 'adbb132c-cf80-4941-ad78-7546ef95acc6',
        marketRole: 'LV',
        name: 'Powerhouse Inclusive B.V.',
        nameFacade: 'Powerhouse Inclusive B.V.',
        status: 'ACTIVE',
        subUnits: [],
      },
    ],
    name: 'Powerhouse Inclusive B.V.',
    nameFacade: 'Powerhouse Inclusive B.V.',
    registrationNumber: '12918372',
    registrationNumberOrigin: 'NL',
  },
  organizationType: 'subunit',
  status: 'ACTIVE',
  ...overrides,
});

type CreateOrganizationConfig = Omit<
  ModelsOrganizationResponse,
  'organizationStructure' | 'organizationType' | 'serviceAccountServiceRoles' | 'userServiceRoles'
>;

export function createOrganization1(seed: number): CreateOrganizationConfig {
  faker.seed(seed);

  const id = faker.string.uuid();
  const externalId = faker.string.uuid();
  const ean13 = faker.string.numeric(13);
  return {
    externalId: externalId,
    id: id,
    name: 'Powerhouse Inclusive B.V.',
    ean13,
    marketRole: 'LV',
    status: 'ACTIVE',
  };
}

export const createOrganizations = (): ModelsOrganizationResponse[] => [
  createOrganization(),
  {
    externalId: '88c54108-30e2-4fcb-8123-09e6b37bc2c0',
    id: '726eca22-1452-4f4c-818e-30f8e45af0d5',
    name: 'Relaxo N.V. (MV)',
    ean13: '81982371818191',
    marketRole: 'ODA',
    parentId: '75a12a3b-019d-4af9-ba91-1e753ec3b888',
    organizationType: 'subunit',
    status: 'ACTIVE',
    organizationStructure: {
      externalId: '',
      id: 'fcb357d6-7843-4d03-bf09-76a331544335',
      marketParticipants: [
        {
          ean13: '81982371818191',
          externalId: '',
          id: '21750b7f-01cb-4b0e-a838-ae012e0060f7',
          marketRole: 'ODA',
          name: 'Relaxo N.V. (MV)',
          nameFacade: 'Relaxo N.V. (MV)',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '81982371818191',
              externalId: '',
              id: '77e590c8-2a76-4807-916c-2489b5ba5b50',
              name: 'Relaxo N.V. (MV)',
              nameFacade: 'Relaxo N.V. (MV)',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Relaxo N.V. (MV)',
      nameFacade: 'Relaxo N.V. (MV)',
      registrationNumber: '00000000',
      registrationNumberOrigin: 'NL',
    },
  },
  {
    externalId: '93d8e98a-4749-4201-939f-9d06d8488aa2',
    id: '195eca22-1442-4f4d-818f-30f7e45af3e0',
    name: 'No ean B.V.',
    ean13: '',
    parentId: '',
    organizationType: 'subunit',
    status: 'ACTIVE',
    organizationStructure: {
      externalId: '',
      id: 'fcb357d6-7843-4d03-bf09-76a331544335',
      marketParticipants: [],
      name: 'No ean B.V.',
      nameFacade: 'No ean B.V.',
      registrationNumber: '00000000',
      registrationNumberOrigin: 'NL',
    },
  },
  {
    externalId: '3fadc4a4-f528-4706-a0e6-20fef506ab75',
    id: 'ed049cd5-8052-4e0e-b537-6866888c0046',
    name: 'WasNL (gaslicht)',
    ean13: '18182712362512',
    marketRole: 'ODA',
    parentId: 'e46bd2a3-dcb9-49d2-a3ba-1c655b1d3926',
    organizationType: 'subunit',
    status: 'ACTIVE',
    organizationStructure: {
      externalId: '',
      id: '8a2a13fa-e1f9-4e17-9875-b8de9cac134b',
      marketParticipants: [
        {
          ean13: '1938287462810',
          externalId: '',
          id: '3d762b58-2760-48f3-9d86-46f009af85cb',
          marketRole: 'ODA',
          name: 'WasNL (gaslicht)',
          nameFacade: 'WasNL (gaslicht)',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '18182712362512',
              externalId: '',
              id: 'ed049cd5-8052-4e0e-b537-6866888c0046',
              name: 'WasNL (gaslicht)',
              nameFacade: 'WasNL (gaslicht)',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Powerhouse Inclusive B.V.',
      nameFacade: 'Powerhouse Inclusive B.V.',
      registrationNumber: '01838718',
      registrationNumberOrigin: 'NL',
    },
  },
  {
    externalId: 'd1b93f00-8d15-4afb-bdd7-bca96b7b0646',
    id: '6277ed9b-0eb1-45a9-b1b0-cc09583528b8',
    name: 'Zicht B.V.',
    ean13: '82991717271891',
    marketRole: 'ODA',
    parentId: '4ddf04ab-e477-4d5e-8403-07b8d49c0043',
    organizationType: 'subunit',
    status: 'ACTIVE',
    organizationStructure: {
      externalId: '',
      id: 'a52ae60d-b7ea-4d83-9629-f7d78344d6d7',
      marketParticipants: [
        {
          ean13: '82991717271891',
          externalId: '',
          id: 'fe8913d1-fb14-482a-aac9-33ac77abaa34',
          marketRole: 'ODA',
          name: 'Zicht B.V.',
          nameFacade: 'Zicht B.V.',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '82991717271891',
              externalId: '',
              id: 'd4f0c3a7-2d8f-4c4f-8b2c-4f1b3d3b0c6e',
              name: 'Zicht B.V.',
              nameFacade: 'Zicht B.V.',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Zicht B.V.',
      nameFacade: 'Zicht B.V.',
      registrationNumber: '191737171',
      registrationNumberOrigin: 'NL',
    },
  },
  {
    externalId: '911eef99-43cb-4b1e-9260-77a1dd384d0e',
    id: '19a05c48-95c5-492d-8195-a0658baa71d2',
    name: 'Interactive Developers Nederland B.V.',
    ean13: '8946184726372',
    marketRole: 'ODA',
    parentId: '1c8d745c-5475-421c-ae6e-6320b5648c89',
    organizationType: 'subunit',
    status: 'ACTIVE',
    organizationStructure: {
      externalId: '',
      id: '1c8d745c-5475-421c-ae6e-6320b5648c89',
      marketParticipants: [
        {
          ean13: '8946184726372',
          externalId: '',
          id: 'a6c3b71f-4ab1-4d53-bd42-3e03e6a50142',
          marketRole: 'ODA',
          name: 'Interactive Developers Nederland B.V.',
          nameFacade: 'Interactive Developers Nederland B.V.',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '8946184726372',
              externalId: '',
              id: 'ef9a19c4-e07d-423e-bc21-03c78e4d49be',
              name: 'Interactive Developers Nederland B.V.',
              nameFacade: 'Interactive Developers Nederland B.V.',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Interactive Developers Nederland B.V.',
      nameFacade: 'Interactive Developers Nederland B.V.',
      registrationNumber: '09183777',
      registrationNumberOrigin: 'NL',
    },
  },
  {
    externalId: '6114b6fa-22c6-47d0-ae0d-79b91bcf8c16',
    id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
    name: 'Enrgy Included B.V.',
    ean13: '88163718371893',
    marketRole: 'EDSN',
    parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
    organizationType: 'subunit',
    status: 'ACTIVE',
    organizationStructure: {
      externalId: '',
      id: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
      marketParticipants: [
        {
          ean13: '88163718371893',
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
          marketRole: 'EDSN',
          name: 'Enrgy Included B.V.',
          nameFacade: 'Enrgy Included B.V.',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '88163718371893',
              externalId: '',
              id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
              name: 'Enrgy Included B.V.',
              nameFacade: 'Enrgy Included B.V.',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Enrgy Included B.V.',
      nameFacade: 'Enrgy Included B.V.',
      registrationNumber: '94712623',
      registrationNumberOrigin: 'NL',
    },
  },
  createMainOrganization(),
  createMarketParticipant(),
  createSubMarketParticipant(),
];

export function createSubMarketParticipant(): ModelsOrganizationResponse {
  return {
    externalId: 'subunit-id',
    id: 'subunit-id',
    name: 'Sub Market Participant',
    ean13: '88163718371898',
    organizationType: 'subunit',
    status: 'ACTIVE',
    parentId: 'market-participant-id',
    organizationStructure: {
      externalId: 'main-org-id',
      id: 'main-org-id',
      marketParticipants: [
        {
          ean13: '88163718371898',
          externalId: 'market-participant-id',
          id: 'market-participant-id',
          marketRole: 'DDQ',
          name: 'Market Participant',
          nameFacade: 'Market Participant',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '88163718371898',
              externalId: 'subunit-id',
              id: 'subunit-id',
              name: 'Sub Market Participant',
              nameFacade: 'Sub Market Participant',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Market Participant',
      nameFacade: 'Market Participant',
      registrationNumber: '94712628',
      registrationNumberOrigin: 'NL',
    },
  };
}

export function createMarketParticipant(): ModelsOrganizationResponse {
  return {
    externalId: 'market-participant-id',
    id: 'market-participant-id',
    name: 'Market Participant',
    ean13: '88163718371898',
    marketRole: 'DDQ',
    organizationType: 'marketparticipant',
    status: 'ACTIVE',
    parentId: 'main-org-id',
    organizationStructure: {
      externalId: 'main-org-id',
      id: 'main-org-id',
      marketParticipants: [
        {
          ean13: '88163718371898',
          externalId: 'market-participant-id',
          id: 'market-participant-id',
          marketRole: 'DDQ',
          name: 'Market Participant',
          nameFacade: 'Market Participant',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '88163718371898',
              externalId: 'subunit-id',
              id: 'subunit-id',
              name: 'Market Participant',
              nameFacade: 'Market Participant',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Market Participant',
      nameFacade: 'Market Participant',
      registrationNumber: '94712628',
      registrationNumberOrigin: 'NL',
    },
  };
}

export function createMainOrganization(): ModelsOrganizationResponse {
  return {
    externalId: 'main-org-id',
    id: 'main-org-id',
    name: 'Main Organization',
    organizationType: 'organization',
    status: 'ACTIVE',
    organizationStructure: {
      externalId: 'main-org-id',
      id: 'main-org-id',
      marketParticipants: [
        {
          ean13: '88163718371899',
          externalId: 'market-participant-id',
          id: 'market-participant-id',
          marketRole: 'DDQ',
          name: 'market participant',
          nameFacade: 'market participant',
          status: 'ACTIVE',
          subUnits: [
            {
              ean13: '88163718371899',
              externalId: 'subunit-id',
              id: 'subunit-id',
              name: 'subunit',
              nameFacade: 'subunit',
              status: 'ACTIVE',
            },
          ],
        },
      ],
      name: 'Main Organization',
      nameFacade: 'Main Organization',
      registrationNumber: '94712629',
      registrationNumberOrigin: 'NL',
    },
    registrationNumber: '94712629',
  };
}
